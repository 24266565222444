<template>
  <div>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="delinquents"
        :page.sync="page"
        :items-per-page="delinquentsPerPage"
        :server-items-length="totalDelinquents"
        hide-default-footer
        :single-select="singleSelect"
        show-select
        @page-count="pageCount = $event"
        sort-by="name"
        class="elevation-1 ml-4"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-col
            cols="12"
            sm="3"
          >
          <v-select
            :items="filters"
            item-text="name"
            item-value="id"
            v-model="filter"
            dense
            outlined
            color="grey"
            hint="Filter by"
            persistent-hint
            class="mt-8 mx-4"
            solo
          />
          </v-col>
          <v-col
            cols="12"
            sm="2"
            v-if="filter === 'Arrival Date'"
          >
          <v-menu
            ref="fromDateMenu"
            v-model="fromDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedFromDate"
                label="From Date"
                outlined
                dense
                class="mt-8"
                solo
                hint="From"
                persistent-hint
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="fromDate"
              :max="new Date().toISOString().substr(0, 10)"
              min="1900-01-01"
              no-title
              @change="saveFromDate"
            ></v-date-picker>
          </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="2"
            v-if="filter === 'Arrival Date'"
          >
          <v-menu
            ref="toDateMenu"
            v-model="toDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedToDate"
                label="To Date"
                outlined
                dense
                class="mt-8"
                solo
                hint="To"
                persistent-hint
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="toDate"
              :max="new Date().toISOString().substr(0, 10)"
              min="1900-01-01"
              no-title
              @change="saveToDate"
            ></v-date-picker>
          </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            v-if="filter === 'Name'"
          >
          <v-text-field
            v-model="names"
            dense
            outlined
            color="grey"
            hint="Filter by Name"
            persistent-hint
            class="mt-8 mx-4"
            solo
          />
          </v-col>
          <v-btn
            class="mt-2"
            elevation="1"
            fab
            small
            @click="filterBy"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded disabled right>{{currentCenter.name}}</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.names`]="{ item }">
          <p @click="loadProfile(item)" class=" btn blue--text">{{ item.names }}</p>
        </template>
      </v-data-table>
      <v-card class="ml-4 mt-1">
        <v-row>
          <v-col
          cols="12"
          sm="2"
        >
        <v-select
          :items="intakes"
          item-text="name"
          item-value="id"
          v-model="intake"
          dense
          label="Select Intake"
          outlined
          color="grey"
          class="mx-4"
          solo
        />
        </v-col>
        <v-col
          cols="12"
          sm="2"
        >
        <v-btn class="green" dense @click="enrolToIntake">Save</v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="2">
          <p class="mx-4">{{selected.length}} Selected </p>
        </v-col>
        </v-row>
        </v-card>
        <div class="pt-2 px-12">
        <v-row>
        <v-col cols="12" sm="2">
        <v-select
          :items="selectDelinquentsPerPage"
          v-model="delinquentsPerPage"
          label="Delinquents per page"
          dense
        ></v-select>
        </v-col>
        <v-col cols="12" sm="8">
        <v-pagination
          v-model="page"
          :length="pageCount"
          circle
          total-visible="7"
          color="blue"
        ></v-pagination>
        </v-col>
        <v-col cols="12" sm="2">
          {{totalDelinquents}} Records
        </v-col>
        </v-row>
      </div>
  <v-snackbar
      v-model="snackbar"
      top
      :color="color"
      :timeout="timeout"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          icon
          v-bind="attrs"
          @click="setSnackbar(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Delinquent from '@/models/Delinquent'
import Assessment from '@/models/Assessment'
export default {
  data () {
    return {
      dialog: false,
      privileges: null,
      showCreate: false,
      showPanels: false,
      names: null,
      headers: [
        {
          text: 'Names',
          align: 'start',
          sortable: false,
          value: 'names'
        },
        { text: 'Date of Birth', value: 'delinquent.dateOfBirth' },
        { text: 'District of Birth', value: 'delinquent.placeOfBirth.name' },
        { text: 'Arrival Date', value: 'arrivalDate' },
        { text: 'Intake', value: 'intakeName' }
      ],
      headers1: [
        {
          text: 'No',
          align: 'start',
          sortable: false,
          value: 'number'
        },
        { text: 'Assessment Type', value: 'type' },
        { text: 'Date', value: 'date' },
        { text: 'Approach', value: 'approach' },
        { text: 'Tools', value: 'tool' },
        { text: 'Attitude', value: 'clientAttitude' },
        { text: 'Outcome', value: 'outcome' },
        { text: 'Observation', value: 'observation' }
      ],
      headers2: [
        {
          text: 'No',
          align: 'start',
          sortable: false,
          value: 'number'
        },
        { text: 'Trade', value: 'training' },
        { text: 'Trade Level', value: 'level' },
        { text: 'Start Date', value: 'startDate' },
        { text: 'End Date', value: 'endDate' },
        { text: 'Status', value: 'status' }
      ],
      delinquents: [],
      assessments: [],
      trainings: [],
      selectDelinquentsPerPage: [2, 5, 10, 15, 20, 25, 30],
      page: 1,
      pageCount: 0,
      delinquentsPerPage: 20,
      totalDelinquents: 0,
      singleSelect: false,
      filters: ['', 'Arrival Date', 'Name'],
      filter: '',
      fromDate: new Date().toISOString(),
      fromDateMenu: false,
      toDate: new Date().toISOString(),
      toDateMenu: false,
      intakes: [],
      intake: null,
      selected: [],
      assessmentTypes: [],
      birthProvinces: [],
      apprehensionProvinces: [],
      residenceProvinces: [],
      birthDistricts: [],
      apprehensionDistricts: [],
      residenceDistricts: [],
      birthSectors: [],
      apprehensionSectors: [],
      residenceSectors: [],
      birthCells: [],
      apprehensionCells: [],
      residenceCells: [],
      birthVillages: [],
      apprehensionVillages: [],
      residenceVillages: [],
      editedAssessmentIndex: -1,
      editedDelinquent: new Delinquent(),
      editedAssessment: new Assessment(),
      delinquentItem: null,
      transferId: null,
      assessmentDate: null,
      retrievedAssessmentDate: null,
      assessmentDateMenu: false,
      errors: null,
      panel: 3
    }
  },

  computed: {
    formattedAssessmentDate () {
      return this.assessmentDate ? this.$moment(this.assessmentDate).format('DD-MMM-YYYY')
        : this.retrievedAssessmentDate ? this.$moment(this.retrievedAssessmentDate).format('DD-MMM-YYYY')
          : ''
    },
    formattedFromDate () {
      return this.fromDate ? this.$moment(this.fromDate).format('DD-MMM-YYYY')
        : this.fromDate ? this.$moment(this.fromDate).format('DD-MMM-YYYY')
          : ''
    },
    formattedToDate () {
      return this.toDate ? this.$moment(this.toDate).format('DD-MMM-YYYY')
        : this.retrievedToDate ? this.$moment(this.retrievedToDate).format('DD-MMM-YYYY')
          : ''
    },
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'currentUser', 'currentCenter', 'search', 'storeProvinces', 'storeProvincesError', 'deviantActs', 'identificationDocuments', 'apprehensionItems'])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.loadAlldelinquents()
      }
    },
    delinquentsPerPage: {
      handler () {
        this.page = 1
        this.loadAlldelinquents()
      }
    }
  },
  mounted () {
    this.loadAlldelinquents()
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  created () {
    this.loadBirthProvinces()
    this.showPanels = true
    this.loadIntakes()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('store', ['loadStoreProvinces']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setDelinquentItem: 'SET_DELINQUENT_ITEM'
    }),
    loadAlldelinquents () {
      const pageNumber = this.page - 1
      this.$delinquentService.fetchAllTransfers(null, false, null, null, 'REHABILITATION', pageNumber, this.delinquentsPerPage)
        .then(response => {
          if (!response.status) {
            this.delinquents = response.content
            this.totalDelinquents = response.totalElements
            this.delinquents.forEach(element => {
              element.names = element.delinquent.firstName + ' ' + element.delinquent.lastName
              element.delinquent.dateOfBirth = this.$moment(element.delinquent.dateOfBirth).format('DD-MMM-YYYY')
              element.arrivalDate = this.$moment(element.arrivalDate).format('DD-MMM-YYYY')
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    loadAssessments () {
      this.$delinquentService.fetchDelinquentAssessments(this.transferId)
        .then(response => {
          if (!response.status) {
            this.assessments = response
            this.assessments.forEach(element => {
              element.date = this.$moment(element.date).format('DD-MMM-YYYY')
              element.number = this.assessments.indexOf(element) + 1
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Assessments: ' + response.message)
          }
        })
    },
    loadBirthProvinces () {
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else this.reloadStoreProvinces()
    },
    async reloadStoreProvinces () {
      await this.loadStoreProvinces
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('Provinces: ' + this.storeProvincesError)
      }
    },
    loadProfile (item) {
      this.setDelinquentItem(item)
      this.$router.push('/delinquent-profile')
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedAssessment = Object.assign({}, this.defaultAssessmentItem)
        this.editedAssessmentIndex = -1
      }, 300)
    },
    loadIntakes () {
      this.$delinquentService.fetchIntakes('OPEN')
        .then(response => {
          if (!response.status) {
            this.intakes = response
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    enrolToIntake () {
      this.$delinquentService.enrolToIntake(this.intake, this.selected)
        .then(response => {
          if (response.status === 'success') {
            this.loadAlldelinquents()
            this.setSnackbar(true)
            this.setColor('green')
            this.setText('Intake assigned successfully')
            this.selected = []
            // this.selected.forEach(element => {
            //   element.intakeName = this.intake
            // })
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    filterByDate () {
      const dateFromTo = this.formattedFromDate + ',' + this.formattedToDate
      this.$delinquentService.fetchTransfersFromTo(dateFromTo)
        .then(response => {
          if (!response.status) {
            this.delinquents = response.content
            this.delinquents.forEach(element => {
              element.names = element.delinquent.firstName + ' ' + element.delinquent.lastName
              element.delinquent.dateOfBirth = this.$moment(element.delinquent.dateOfBirth).format('YYYY')
              element.arrivalDate = this.$moment(element.arrivalDate).format('DD-MMM-YYYY')
            })
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    filterByName () {
      if (this.names) {
        this.$delinquentService.searchName('deviant_acts', 'REHABILITATION', this.names)
          .then(response => {
            if (!response.status) {
              this.delinquents = response.content
              this.delinquents.forEach(element => {
                element.names = element.delinquent.firstName + ' ' + element.delinquent.lastName
                element.delinquent.dateOfBirth = this.$moment(element.delinquent.dateOfBirth).format('YYYY')
                element.arrivalDate = this.$moment(element.arrivalDate).format('DD-MMM-YYYY')
              })
            } else {
              // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      } else {
        this.loadAlldelinquents()
      }
    },
    filterBy () {
      if (this.filter === 'Arrival Date') {
        this.filterByDate()
      } else if (this.filter === 'Name') {
        this.filterByName()
      } else this.loadAlldelinquents()
    },
    saveFromDate (fromDate) {
      this.$refs.fromDateMenu.save(fromDate)
    },
    saveToDate (toDate) {
      this.$refs.toDateMenu.save(toDate)
    },
    fetchBirthSiblings () {
      this.$locationService.fetchAllSiblings(this.editedDelinquent.placeOfBirth).then(response => {
        if (response.status) {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Location: ' + response.message)
        } else [this.birthDistricts, this.birthSectors, this.birthCells, this.birthVillages] = response
      })
    },
    fetchApprehensionSiblings () {
      this.$locationService.fetchAllSiblings(this.editedDelinquent.apprehension.apprehensionPlace).then(response => {
        if (response.status) {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Location: ' + response.message)
        } else [this.apprehensionDistricts, this.apprehensionSectors, this.apprehensionCells, this.apprehensionVillages] = response
      })
    },
    fetchResidenceSiblings () {
      this.$locationService.fetchAllSiblings(this.editedDelinquent.apprehension.delinquentResidence).then(response => {
        if (response.status) {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Location: ' + response.message)
        } else [this.residenceDistricts, this.residenceSectors, this.residenceCells, this.residenceVillages] = response
      })
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_DELINQUENTS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showCreate = true : this.showCreate = false
      this.privileges.indexOf('UPDATE_DELINQUENTS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showEdit = true : this.showEdit = false
      this.privileges.indexOf('DELETE_DELINQUENTS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showDelete = true : this.showDelete = false
    }
  }
}
</script>
<style scoped>
.btn {
  cursor: pointer;
}
</style>
